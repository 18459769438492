import React, { useState, useEffect } from "react";
// import { useRouter } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";

// import baseUrl from "@/app/api-config";
const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  //   const router = useRouter();

  const handleLogin = async () => {
    // Basic required field validation
    if (!email || !password) {
      setError("Email and password are required.");
      return;
    }

    try {
      const response = await fetch(`https://attendance-api.rktechs.io/login/user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `email=${encodeURIComponent(email)}&password=${encodeURIComponent(
          password
        )}`,
      });

      if (response.ok) {
        const data = await response.json();

        // Extract user and token information from the response
        const { user, token } = data.data;

        // Store the token in localStorage
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user));

        // Reset state and perform any necessary actions (e.g., redirect)
        setEmail("");
        setPassword("");
        setError(null);
        console.log("Login successful");
        navigate("/dashboard");
        // Redirect to a different page after successful login
        // router.push("/dashboard"); // Adjust the path accordingly
      } else {
        const data = await response.json();
        setError(data.error || "Login failed");
      }
    } catch (error) {
      console.error("Error during login:", error);
      setError("An error occurred during login.");
    }
  };

  useEffect(() => {
    // Check if the user is authenticated
    const token = localStorage.getItem("token");

    if (token) {
      navigate("/dashboard");
      // Redirect to the login page if the user is not authenticated
      //   router.push("/");
    }
  }, []);

  return (
    <div className="custom-form-container">
      <div className="custom-form">
        <h2 className="text-center">Login</h2>
        {error && <p className="error-message">{error}</p>}
        <form>
          <div>
            <label>Email:</label>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="custom-input"
            />
          </div>
          <div>
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="custom-input"
            />
          </div>
          <div className="flex justify-evenly text-center">
            {/* <Link to="/register" className="text-blue-500">
              Register an account
            </Link> */}{" "}
            <br />
            <button type="button" onClick={handleLogin} className="loginbutton">
              Login
            </button>
          </div>
        </form>
        <div className="mt-4"></div>
      </div>
    </div>
  );
};

export default Login;
