import React, { useState, useEffect } from "react";
import logo from "../images.jpg";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    // Add your logout logic here
    // For example, clearing user data from local storage and redirecting to the login page
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    navigate("/");
    // Trigger the parent component's logout function
  };
  const user = JSON.parse(localStorage.getItem("user"));

  //   useEffect(() => {
  //     // Check if the user is authenticated
  //     const token = localStorage.getItem("token");

  //     if (token) {
  //       navigate("/dashboard");
  //       // Redirect to the login page if the user is not authenticated
  //       //   router.push("/");
  //     }
  //   }, []);
  if (!user) {
    // If user is not available, redirect to /login
    navigate("/");
    return null; // Optional: Render nothing or a loading indicator
  }

  return (
    <header className="flex items-center justify-between  p-4 text-black">
      <div className="flex items-center">
        <img src={logo} alt="Logo" className="h-8 w-8 mr-2" />
        <h3>
          <span>Ahmadiyya Muslim Jamaat</span>
          <br />
          <span>Deutschland KdöR</span>
        </h3>
      </div>

      <button
        onClick={handleLogout}
        className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded bg-black"
      >
        Logout
      </button>
    </header>
  );
};

export default Header;
