import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginPage from "../pages/login";
import Register from "../pages/register";
import Dashboard from "../pages/dashboard";
import Data from "../pages/showAttandance";

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/register" element={<Register />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/data" element={<Data />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
