import React, { useState, useEffect } from "react";
import AttendanceList from "../components/group"; // Adjust the path as needed
import Header from "../components/header";

const App = () => {
  const [attendanceData, setAttendanceData] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await fetch("https://attendance-api.rktechs.io/blog/show");
        if (response.ok) {
          const data = await response.json();
          setAttendanceData(data.data);
        } else {
          console.error("Failed to fetch data:", response.statusText);
        }
      } catch (error) {
        console.error("Error during data fetching:", error);
      }
    };

    fetchData(); // Call the fetchData function when the component mounts
  }, []); // The empty dependency array ensures that this effect runs only once

  return (
    <>
      <Header />
      <div>
        {console.log(attendanceData)}
        {/* Other components or content */}
        <AttendanceList attendanceData={attendanceData} />
      </div>
    </>
  );
};

export default App;
