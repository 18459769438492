import React, { useState } from "react";
import { Link } from "react-router-dom";

const Register = () => {
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const [registrationSuccess, setRegistrationSuccess] = useState(false);

  const handleRegister = async () => {
    // Clear previous errors
    setError(null);
    setRegistrationSuccess(false);

    // Basic required field validation
    if (!name.trim() || !code.trim() || !email.trim() || !password.trim()) {
      setError("Name, email, code, and password are required.");
      return;
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    // Password validation (add more rules as needed)
    if (password.length < 6) {
      setError("Password must be at least 6 characters long.");
      return;
    }

    // Call the registration API
    try {
      const response = await fetch(`https://attendance-api.rktechs.io/user/registerUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `name=${encodeURIComponent(name)}&email=${encodeURIComponent(
          email
        )}&password=${encodeURIComponent(
          password
        )}&aimsCode=${encodeURIComponent(code)}`,
      });

      if (response.ok) {
        // Registration successful
        setRegistrationSuccess(true);
        // You can redirect or perform other actions here
      } else {
        // Registration failed
        const data = await response.json();
        setRegistrationSuccess(false);
        setError(data.error || "Registration failed");
      }
    } catch (error) {
      console.error("Error during registration:", error);
      setRegistrationSuccess(false);
      setError("An error occurred during registration.");
    }
  };

  return (
    <div className="custom-form-container">
      <div className="custom-form">
        <h2 className="text-2xl font-bold mb-4">Register</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {registrationSuccess && (
          <p className="text-green-500 mb-4">Registration successful!</p>
        )}
        <form className="space-y-4">
          <div>
            <label className="block text-sm font-semibold text-gray-600">
              Center Name:
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="custom-input"
              />
            </label>
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600">
              Center Code:
              <input
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                className="custom-input"
              />
            </label>
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600">
              Email:
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="custom-input"
              />
            </label>
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600">
              Password:
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="custom-input"
              />
            </label>
          </div>
          <div className="flex justify-evenly">
            <button
              type="button"
              onClick={handleRegister}
              className="loginbutton"
            >
              Register
            </button>{" "}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
