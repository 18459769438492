import React from "react";

const AttendanceList = ({ attendanceData }) => {
  // Group the attendance data by user, date, and namaz
  const userReports = attendanceData.reduce((acc, entry) => {
    const key = `${entry.name}_${new Date(
      entry.timestamp
    ).toLocaleDateString()}_${entry.Namaz}`;
    if (!acc[key]) {
      acc[key] = {
        date: new Date(entry.timestamp).toLocaleDateString(),
        centerCode: entry.aimsCode,
        namaz: entry.Namaz,
        ansarAttendance: 0,
        khuddamAttendance: 0,
        itfalAttendance: 0,
        totalAttendance: 0,
      };
    }
    // Count attendance for each group
    switch (entry.groups) {
      case "ansar":
        acc[key].ansarAttendance += entry.Attandance;
        break;
      case "khuddam":
        acc[key].khuddamAttendance += entry.Attandance;
        break;
      case "itfal":
        acc[key].itfalAttendance += entry.Attandance;
        break;
      default:
        break;
    }
    // Count total attendance
    acc[key].totalAttendance += entry.Attandance;
    return acc;
  }, {});

  // Extract user reports as an array
  const userReportsArray = Object.values(userReports);

  return (
    <div>
      <h2>Attendance List</h2>
      <table className="excel-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Center Code</th>
            <th>Namaz</th>
            <th>Ansar Attendance</th>
            <th>Khuddam Attendance</th>
            <th>Itfal Attendance</th>
            <th>Total Attendance</th>
          </tr>
        </thead>
        <tbody>
          {userReportsArray.map((report) => (
            <tr key={`${report.date}_${report.centerCode}_${report.namaz}`}>
              <td>{report.date}</td>
              <td>{report.centerCode}</td>
              <td>{report.namaz}</td>
              <td>{report.ansarAttendance}</td>
              <td>{report.khuddamAttendance}</td>
              <td>{report.itfalAttendance}</td>
              <td>{report.totalAttendance}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AttendanceList;
