// AddAttandance.jsx
import React, { useState, useEffect } from "react";
import Header from "../components/header";
import { Link, useNavigate } from "react-router-dom";
const AddAttandance = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [groups, setGroups] = useState("");
  const [Attandance, setAttandance] = useState("");
  const [Namaz, setNamaz] = useState("");

  const [alert, setAlert] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));

  if (!user) {
    // If user is not available, redirect to /login
    navigate("/login");
    // return null; // Optional: Render nothing or a loading indicator
  }
  // console.log(user.aimsCode);

  const handleAddAttandance = async () => {
    try {
      const token = localStorage.getItem("token");

      const response = await fetch(`https://attendance-api.rktechs.io/blog/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
        body: `name=${encodeURIComponent(
          user.name
        )}&groups=${encodeURIComponent(groups)}&email=${encodeURIComponent(
          user.email
        )}&Attandance=${encodeURIComponent(
          Attandance
        )}&Namaz=${encodeURIComponent(Namaz)}&aimsCode=${encodeURIComponent(
          user.aimsCode
        )}`,
      });

      if (response.ok) {
        setAlert("Attandance added successfully");
      } else {
        const data = await response.json();
        setAlert("Failed to add Attandance:", data.error || "Unknown error");
        // console.error(
        //   "Failed to add Attandance:",
        //   data.error || "Unknown error"
        // );
      }
    } catch (error) {
      console.error("Error during Attandance addition:", error);
    }
  };

  useEffect(() => {
    if (alert) {
      const timeoutId = setTimeout(() => {
        setAlert(null); // Hide the alert after 3 seconds
      }, 2000);

      // Clear the timeout when the component unmounts or when alert changes
      return () => clearTimeout(timeoutId);
    }
  }, [alert]);

  // Function to show the alert
  const showAlert = (message) => {
    setAlert(message);
  };
  const handleShowDataClick = () => {
    // Redirect to the /data route
    navigate("/data");
  };
  return (
    <>
      <Header />
      <button
        onClick={handleShowDataClick}
        className="mr-4 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
      >
        Show
      </button>
      <main className="flex flex-col items-center justify-between p-24">
        <div className="max-w-md mx-auto my-8">
          <h2 className="text-2xl font-bold mb-4">Add New Attandance</h2>
          {alert && (
            <div
              className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded-md mb-4"
              role="alert"
            >
              {alert}
            </div>
          )}

          <form className="space-y-8">
            <div>
              <label className="block text-sm font-semibold text-gray-600">
                namaz center/ mosque:
                <input type="text" value={user.name} className="formInput" />
              </label>
            </div>
            <div>
              <label className="block text-sm font-semibold text-gray-600">
                Groups:
                <select
                  value={groups}
                  onChange={(e) => setGroups(e.target.value)}
                  className="formSelect"
                >
                  {" "}
                  <option value="" disabled>
                    Select a Groups
                  </option>
                  <option value="ansar">Ansar</option>
                  <option value="khuddam">Khuddam</option>
                  <option value="itfal">Itfal</option>
                </select>
              </label>
            </div>
            <div>
              <label className="block text-sm font-semibold text-gray-600">
                Namaz :
                <select
                  value={Namaz}
                  onChange={(e) => setNamaz(e.target.value)}
                  className="formSelect"
                >
                  {" "}
                  <option value="" disabled>
                    Select a Namaz
                  </option>
                  <option value="Fajr">Fajr</option>
                  <option value="zuhr">zuhr</option>
                  <option value="Asar">Asar</option>
                  <option value="Maghrib">Maghrib</option>
                  <option value="Isha">Isha</option>
                </select>
              </label>
            </div>
            <div>
              <label className="block text-sm font-semibold text-gray-600">
                Attandance:
                <input
                  type="number"
                  value={Attandance}
                  onChange={(e) => setAttandance(e.target.value)}
                  className="formInput"
                />
              </label>
            </div>
            <br />
            <div className="  ">
              <button
                type="button"
                onClick={handleAddAttandance}
                className="formButton"
              >
                Add Attandance
              </button>
            </div>
          </form>
        </div>
      </main>
    </>
  );
};

export default AddAttandance;
